<div [style.margin-top]="topMargin" id="header"></div>
<div [style.margin-top]="topMargin" class="fxp-left-nav-l1-menu"></div>

<div id="displayDiv" *ngIf="!isBannerHidden">
    <div id="browser-switch-banner">
        <div id="browser-switch-banner-content">
            <div class="browser-experience-image-section">
                <img alt="New Edge Icon" src="assets/logoedgedev.png" class="browser-experience-image" />
                {{ message }}
            </div>
            <div class="browser-experience-link-section">
                <a href="https://www.microsoftedgeinsider.com/en-us/" target="_blank" class="fxp-btn fxp-btn--action"
                    aria-label="Edge download link">Try new improved Edge</a>
                <input type="button" (click)="remindMeLaterClick()" name="RML" id="RML" value="Remind me later"
                    class="browser-experience-remind" />
            </div>
        </div>
    </div>
</div>