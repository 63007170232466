<div class="fxpmessage">
    <div class="modal {{messageClass}}">
        <div *ngFor="let message of messages; let i = index" class="message message-{{message.MessageType}}"
            [attr.aria-labelledby]="'message-content-' + i" (keydown)="onMessageKeyDown($event)">
            <div class="icon-container">
                <i class="icon" [ngClass]="'icon-' + message.MessageType"></i>
            </div>
            <div class="message-container">
                <div role="alert" class="message-content" [attr.id]="'message-content-' + i" tabindex="-1">
                    <span class="message-content-text">{{message.Message}}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                        class="msgTrackingId" *ngIf="message.MessageType==='error' && message.trackId !== ''">
                        ({{message.trackId}})&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span><span class="msgTrackingId"
                        *ngIf="message.MessageType==='error' && message.uniqueTransactionId !== ''">
                        ({{message.uniqueTransactionId}})
                    </span>
                    <span *ngIf="message.MessageType==='reload'">
                        <button class="button fxp-btn fxp-btn--primary" (click)="clearAndReload()"
                            style="min-height:25px;">
                            <b class="icon icon-refresh"
                                style="display: inline; font-size: small; padding-right: 5px; position: relative; top: 2px;"></b>Re-Login
                        </button>
                    </span>
                </div>
                <button class="icon-container" type="button" tabindex="1" (click)="closeMessage(message)"
                    [title]="message.Message + ' Click to close message'">
                    <i class="icon icon-close pull-right"></i>
                </button>
            </div>
        </div>
    </div>
</div>