import { Directive, ElementRef, Input, OnChanges, OnDestroy } from '@angular/core';
import { FxpHelpMenuComponent } from './fxphelp.component';

@Directive({
  selector: '[fxpHelpArticle]'
})
export class FxpHelpArticleDirective implements OnChanges, OnDestroy {
  @Input('fxpHelpArticle') articleTitle: string;
  private imageClickHandler: EventListener;
  private imageKeydownHandler: EventListener;

  constructor(
    private elementRef: ElementRef,
    private fxpmenuhelpcomponent: FxpHelpMenuComponent 
  ) {
    this.imageClickHandler = this.onImageClick.bind(this);
    this.imageKeydownHandler = this.onImageEnterPress.bind(this);
  }

  ngOnChanges() {
    setTimeout(() => {
      this.wrapImage();
    }, 0);
  }

  ngOnDestroy() {
    this.cleanUp();
  }

  private onImageClick(event: MouseEvent) {
    console.log('Image clicked');
    if (this.fxpmenuhelpcomponent?.expandArticleImage) {
      this.fxpmenuhelpcomponent.expandArticleImage((event.target as HTMLImageElement).getAttribute('src'));
    } else {
      console.error('fxpmenuhelpcomponent or expandArticleImage is not defined');
    }
  }

  private onImageEnterPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.fxpmenuhelpcomponent.expandArticleImage((event.target as HTMLImageElement).getAttribute('src'));
    }
  }

  private cleanUp() {
    const images = this.elementRef.nativeElement.querySelectorAll('img');
    images.forEach((image: HTMLImageElement) => {
      image.removeEventListener('click', this.imageClickHandler);
      image.removeEventListener('keydown', this.imageKeydownHandler);
    });
  }

  private wrapImage() {
    const images = this.elementRef.nativeElement.querySelectorAll('img');
    images.forEach((image: HTMLImageElement) => {
      const container = document.createElement('div');
      container.className = 'image-container';
      image.parentElement.insertBefore(container, image);
      container.appendChild(image);
      image.setAttribute('tabindex', '0');
      image.addEventListener('click', this.imageClickHandler);
      image.addEventListener('keydown', this.imageKeydownHandler);
      const icon = document.createElement('i');
      icon.className = 'icon icon-magnify';
      container.appendChild(icon);
    });
  }
}
