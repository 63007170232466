import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FxpLeftNavigationComponent } from './fxpleftnavigation.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FxpLeftNavLinkDirective } from '../../../js/directives/fxpLeftNavLink.directive';
import { FxpStateService } from '../../services/FxpStateRoutingHelperService';
import { AdminLandingServiceProvider, DashboardServiceProvider, DeviceFactoryProvider, FxpContextServiceProvider, FxpEventBroadCastServiceProvider, FxpLoggerServiceProvider, FxpMessageServiceProvider, FxpRouteServiceProvider, SettingsServiceProvider, UserClaimsServiceProvider, UserInfoServiceProvider, UserProfileServiceProvider } from '../../services/FxpServices';
import { LeftNavOutsideClickDirective } from './left-nav-outside-click.directive';


@NgModule({
    declarations: [FxpLeftNavigationComponent, FxpLeftNavLinkDirective, LeftNavOutsideClickDirective],
    entryComponents: [FxpLeftNavigationComponent],
    providers: [
        FxpStateService,
        FxpContextServiceProvider,
        UserInfoServiceProvider,
        FxpEventBroadCastServiceProvider,
        UserClaimsServiceProvider,
        AdminLandingServiceProvider,
        FxpRouteServiceProvider,
        UserProfileServiceProvider,
        DashboardServiceProvider,
        FxpMessageServiceProvider,
        DeviceFactoryProvider,
        FxpLoggerServiceProvider,
        SettingsServiceProvider
    ],
    imports: [CommonModule, NgbModule]
})
export class FxpLeftNavigationModule { }
