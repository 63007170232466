import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FxpMessagesComponent } from "./fxpmessages.component";


@NgModule({
    declarations: [FxpMessagesComponent],
    entryComponents: [FxpMessagesComponent],
    providers: [],
    imports: [CommonModule],
})



export class FxpMessagesModule { }
