import { NgModule, COMPILER_OPTIONS, CompilerFactory } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactAppModule } from './reactapp.module';
import { PowerBiReportContainerModule } from './powerbi-module';
import { FxpStateService, FxpTransitionService } from '../services/FxpStateRoutingHelperService';

import {
    FxpConfigurationServiceProvider,
    AdalLoginHelperServiceProvider,
    DashboardServiceProvider,
    FxpMessageServiceProvider,
    HelpCentralServiceProvider,
    OBOUserServiceProvider,
    pageTourEventServiceProvider,
    SystemMessagesServiceProvider,
    UserInfoServiceProvider,
    UserProfileServiceProvider,
    UserClaimsServiceProvider,
    FxpBreadcrumbServiceProvider,
    AdminLandingServiceProvider,
    DataServiceProvider,
    NotificationActionCenterProvider,
    FxpStorageServiceProvider,
    PersonalizationServiceProvider,
    FxpFeedbackServiceProvider,
    FxpRouteServiceProvider,
    NotificationStoreProvider,
    PageLoaderServiceProvider,
    PlannedDownTimeServiceProvider,
    TimeZoneHelperProvider,
    FxpAuthorizationServiceProvider,
    NotificationServiceProvider,
    FxpEventBroadCastServiceProvider,
    FxpHttpServiceProvider,
    FxpConfitServiceProvider,
    FxpHttpClientServiceProvider,
    FxpUIBModalServiceProvider,
    FxpLoggerServiceProvider,
    FxpFeatureFlagServiceProvider,
    FxpStateTransitionServiceProvider,
    FxpUIDataProvider,
    SettingsServiceProvider,
    DeviceFactoryProvider,
    FxpContextServiceProvider,
    FxpHttpInterceptorHooksProvider,
    LazyLoaderServiceProvider,
    FxpIrisBotServiceProvider,
    FxpGlobalStoreServiceProvider,
    FxpOCVFeedbackServiceProvider,
    FxpSignalRServiceProvider,
    MsalAuthenticationServiceProvider,
    // MsalAuthenticationServiceProvider

} from '../services/FxpServices';
import { AppComponent } from '../components/app-component/app.component';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { PartnerContainerComponent } from '../components/partnerContainer-component/partnerContainer-component';
import { PartnerStateService } from '../components/partnerContainer-component/partnerStateService';
import { UIRouterModule } from '@uirouter/angular';
import { JitCompilerFactory } from '@angular/platform-browser-dynamic';
import { FxpBotModule } from '../components/fxpbot/fxpbot.module';
import { AngularMaterialModule } from './angular.material.module';
import { FxpSharedComponentLoader, FxpsharedcomponentloaderModule } from '@fxp/fxpsharedcomponentloader';
import { FxpPageTourModule } from '../components/pageTour-Component/pageTour.module';

import { SharedComponentsModule, SharedComponentLoader } from '@fxp/angularcontrols';
import { FxpMessagesModule } from '../components/messages-component/fxpmessages.module';
import { FxpHttpClientService } from '../../js/services/FxpHttpClientService';
import { AddAuthTokenInterceptor, AddHeadersInterceptor, AddPartnerInterceptor, RetryInterceptor } from '../services/FxpHttpClientInterceptorFactory';
import { FxpUIBModalService } from '../../js/services/FxpUIBModalService';
import { FxpHeaderModule } from '../components/header-component/fxpheader.module';
import { FxpNotificationModule } from '../components/fxpnotification/fxpnotification.module';
import { FxpLeftNavigationModule } from '../components/fxpleftnavigation-component/fxpleftnavigation.module';
import { FxpFooterModule } from '../components/fxpfooter/fxpfooter.module';
import { BrowserExperienceModule } from '../components/browserExperience/browserExperience.module';
import { FxpSystemUpdateMsgModule } from '../components/fxpsystemupdatemsg/fxpsystemupdatemsg.module';

import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FxpToastNotificationModule } from '../components/fxptoastnotification/fxptoastnotification.module';
let importModules = [
    BrowserModule,
    FormsModule,
    UpgradeModule,
    HttpClientModule,
    UIRouterUpgradeModule,
    UIRouterModule,
    BrowserAnimationsModule,
    NgbModule,
    FxpBotModule,
    FxpMessagesModule,
    FxpHeaderModule,
    FxpNotificationModule,
    FxpToastNotificationModule,
    // FxpHelpModule,
    AngularMaterialModule,
    ReactAppModule,
    FxpsharedcomponentloaderModule,
    PowerBiReportContainerModule,
    FxpPageTourModule,
    SharedComponentsModule,
    FxpLeftNavigationModule,
    FxpFooterModule,
    BrowserExperienceModule,
    FxpSystemUpdateMsgModule
];

export const fxpServices = [
    HttpClientModule,
    FxpConfigurationServiceProvider,
    MsalAuthenticationServiceProvider,
    AdalLoginHelperServiceProvider,
    AdminLandingServiceProvider,
    DashboardServiceProvider,
    DataServiceProvider,
    FxpAuthorizationServiceProvider,
    FxpBreadcrumbServiceProvider,
    FxpFeedbackServiceProvider,
    FxpMessageServiceProvider,
    FxpRouteServiceProvider,
    FxpStorageServiceProvider,
    HelpCentralServiceProvider,
    NotificationActionCenterProvider,
    NotificationServiceProvider,
    NotificationStoreProvider,
    FxpHttpClientServiceProvider,
    FxpUIBModalServiceProvider,
    OBOUserServiceProvider,
    PageLoaderServiceProvider,
    pageTourEventServiceProvider,
    PersonalizationServiceProvider,
    PlannedDownTimeServiceProvider,
    SystemMessagesServiceProvider,
    TimeZoneHelperProvider,
    UserInfoServiceProvider,
    UserProfileServiceProvider,
    FxpConfitServiceProvider,
    UserClaimsServiceProvider,
    FxpEventBroadCastServiceProvider,
    FxpHttpServiceProvider,
    FxpLoggerServiceProvider,
    FxpFeatureFlagServiceProvider,
    FxpStateTransitionServiceProvider,
    FxpUIDataProvider,
    SettingsServiceProvider,
    DeviceFactoryProvider,
    PartnerStateService,
    FxpContextServiceProvider,
    FxpHttpInterceptorHooksProvider,
    LazyLoaderServiceProvider,
    FxpIrisBotServiceProvider,
    FxpOCVFeedbackServiceProvider,
    { provide: COMPILER_OPTIONS, useValue: {}, multi: true },
    { provide: CompilerFactory, useClass: JitCompilerFactory, deps: [COMPILER_OPTIONS] },
    FxpGlobalStoreServiceProvider,
    FxpSignalRServiceProvider,
    FxpHttpClientService,
    FxpUIBModalService,
    FxpStateService,
    FxpTransitionService
];

@NgModule({
    imports: importModules,
    declarations: [AppComponent, PartnerContainerComponent],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AddAuthTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AddHeadersInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AddPartnerInterceptor, multi: true },
        ...fxpServices
    ],
    bootstrap: [
        AppComponent
    ],
    entryComponents: [PartnerContainerComponent, FxpSharedComponentLoader, SharedComponentLoader],
    exports: [PartnerContainerComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {

}




