import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FxpToastNotificationComponent } from './fxptoastnotification.component';
import { NotificationActionCenter } from '../../../js/services/NotificationActionCenter';
import { FxpConfigurationService } from '../../../js/services/FxpConfiguration';
import { FxpMessageService } from '../../banner/FxpMessageService';
import { NotificationStore } from '../../../js/services/NotificationStore';
import { FxpLoggerServiceProvider, FxpMessageServiceProvider, NotificationActionCenterProvider, NotificationStoreProvider } from '../../services/FxpServices';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    FxpToastNotificationComponent
  ],
  entryComponents: [
    FxpToastNotificationComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
    NotificationActionCenterProvider,
    FxpConfigurationService,
    FxpLoggerServiceProvider,
    FxpMessageServiceProvider,
    NotificationStoreProvider
  ]
})
export class FxpToastNotificationModule { }