import { NgModule } from '@angular/core';
import { SharedModule } from '../../modules/fxpheader.shared.module';

@NgModule({
    imports: [SharedModule],
    providers: [],
})



export class FxpPageTourModule { }
