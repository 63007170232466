<div *ngIf="plannedDowntimeService.isFlashVisible || plannedDowntimeService.isSystemAnnouncementVisible">
    <div class="modal modal-show" *ngIf="plannedDowntimeService.isFlashVisible">
        <div class="message message-warning" *ngFor="let flashMessage of plannedDowntimeService.flashMessages">
            <div class="icon-container">
                <i class="icon icon-info"></i>
            </div>
            <div class="message-container">
                <div class="message-content">
                    <span [innerHTML]="flashMessage.message" role="alert" aria-live="assertive"
                        [attr.aria-label]="flashMessage.message">
                    </span>
                </div>
            </div>
            <button class="icon-container close-annoucement" type="button" *ngIf="flashMessage.isDismissible"
                (click)="plannedDowntimeService.closeSystemMessage(flashMessage.id)" aria-label="Close"
                title="Click to close system message">
                <i class="icon icon-close pull-right"></i>
            </button>
        </div>
    </div>

    <div class="modal modal-show" *ngIf="plannedDowntimeService.isSystemAnnouncementVisible">
        <div class="message message-warning">
            <div class="icon-container">
                <i class="icon icon-info"></i>
            </div>
            <div class="message-container">
                <div class="message-content" tabindex="0">
                    <span [innerHTML]="plannedDowntimeService.systemAnnouncementMessage">
                    </span>
                </div>
            </div>
            <button class="icon-container close-annoucement" type="button"
                (click)="plannedDowntimeService.closeSystemAnnouncement()" aria-label="Close"
                title="Click to close system announcement">
                <i class="icon icon-close pull-right"></i>
            </button>
        </div>
    </div>
</div>