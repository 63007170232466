<div class="toast_container toast-show" *ngIf="toastNotifications.length">
    <div *ngFor="let notification of toastNotifications; let i = index" class="notificaion toast-notificaion" [title]="notification.subject">
      <div class="toast-container" [id]="'toast_' + i">
        <span class="pull-left icon-container">
          <i [class]="notification.icon"></i>
        </span>
        <button class="toast-content" [ngClass]="{'has-action': notification.hasAction}" 
                [attr.aria-label]="i==0 ? 'Notification received ' + notification.subject : notification.subject" 
                [title]="notification.subject" (click)="readToastNotification(notification)">
          <span class="multiline-ellipsis-3">{{notification.subject}}</span>
        </button>
      </div>
      <button class="icon-container close-button pull-right" type="button" aria-label="Close" title="Click to close message" (click)="closeToastNotification(notification)">
        <i class="icon icon-close"></i>
      </button>
    </div>
  </div>