import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserExperience } from '../../../js/services/BrowserExperience';
import { BrowserExperienceComponent } from './browserExperience.component';


@NgModule({
    declarations: [BrowserExperienceComponent],
    entryComponents: [BrowserExperienceComponent],
    providers: [
        BrowserExperience
    ],
    imports: [CommonModule]
})
export class BrowserExperienceModule { }
