import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FxpHeaderComponent } from "./fxpheader.component";
import { FxpConfigurationService } from '../../../js/services/FxpConfiguration';
import { DashboardHelperProvider, DeviceFactoryProvider, FxpFeedbackServiceProvider, FxpLoggerServiceProvider, FxpMessageServiceProvider, OBOUserServiceProvider, UserProfileServiceProvider } from '../../services/FxpServices';
import { UserInfoService } from '../../../js/services/UserInfoService';
import { FxpStateService } from '../../services/FxpStateRoutingHelperService';
import { FxpGlobalStoreService } from '../../../js/services/fxp.global.store.service';
import { SharedModule } from '../../modules/fxpheader.shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [FxpHeaderComponent],
    entryComponents: [FxpHeaderComponent],
    providers: [
        FxpConfigurationService,
        UserInfoService,
        FxpStateService,
        FxpGlobalStoreService,
        FxpLoggerServiceProvider,
        FxpMessageServiceProvider,
        DeviceFactoryProvider,
        OBOUserServiceProvider,
        UserProfileServiceProvider,
        DashboardHelperProvider,
        FxpFeedbackServiceProvider
    ],
    imports: [CommonModule, SharedModule, NgbModule]
})
export class FxpHeaderModule { }
