<div *ngFor="let value of [footerdata.LeftAlignedLinks, footerdata.RightAlignedLinks]; let i = index"
    [ngClass]="{'left': i == 0 , 'right': i == 1}" role="presentation">
    <ul role="list">
        <li *ngFor="let item of value" role="listitem" [ngClass]="item.cssClass">
            <ng-container [ngSwitch]="item.ElementType">
                <a *ngSwitchCase="'a'" [id]="'control_' + i" [tabIndex]="0" [href]="item.href"
                    (click)="logFooterUsageTelemetryInfo(item)" target="_blank"
                    [title]="'Click to Open ' + item.DisplayText">{{item.DisplayText}}</a>
                <img *ngSwitchCase="'img'" [id]="'control_img_' + i" [src]="item.ImagePath" [alt]="item.DisplayText" />
                <span *ngSwitchCase="'span'" [id]="'control_span_' + i">{{item.DisplayText}}</span>
                <span *ngSwitchCase="'expression'"
                    [id]="'control_span_' + i">{{item.DisplayText}}{{this[item.ExpressionName]}}</span>
            </ng-container>
        </li>
    </ul>
</div>