import { Directive, Input, ElementRef, Renderer2, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appAriaExpanded]'
})
export class AriaExpandedDirective implements OnChanges {
  @Input('appAriaExpanded') isExpanded: boolean;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isExpanded) {
      const value = this.isExpanded ? 'true' : null;
      if (value) {
        this.renderer.setAttribute(this.el.nativeElement, 'aria-expanded', value);
      } else {
        this.renderer.removeAttribute(this.el.nativeElement, 'aria-expanded');
      }
    }
  }
}
