<section>
    <div class="header-title">
        <h1 role="heading" aria-level="1" class="margin0">
            <button id="Fxpdashboard_ProfessionService" role="link" class="title" tabindex="0"
                [title]="fxpUIConstants.UIStrings.AppHeader" [attr.aria-label]="fxpUIConstants.UIStrings.AppHeader"
                (click)="navigateToLandingPage()">
                {{ getApplicationNameForDisplay() }}
            </button>
        </h1>
        <span class="separator"></span>
        <h2 id="Fxpdashboard_ApplicationSpecifictext" class="title current-app">
            {{ fxpheaderdata.DisplayText }}
        </h2>
    </div>
    <div class="header-empty"></div>
    <div class="pull-right header-right">
        <div class="notification-wrap" *ngIf="initialFlags.notificationsEnabled">
            <fxp-notification></fxp-notification>
        </div>
        <div class="notification-wrap" *ngIf="isBotEnabled">
            <iris-launcher></iris-launcher>
        </div>
        <div class="fxp-profile">
            <fxphelpmenu></fxphelpmenu>
        </div>
        <div class="notification-wrap" *ngIf="initialFlags.pageTourEnabled">
            <page-tour></page-tour>
        </div>
        <div class="notification-wrap" *ngIf="appToggleOCV()">
            <button title="Feedback" id="feedback-open" type="button" class="icon-container withBorder"
                (click)="initializeFeedback()" [attr.aria-expanded]="isFeedbackDialogOpen">
                <i class="icon icon-smile"></i>
            </button>
        </div>
        <div class="fxp-profile" *ngIf="isDeviceMobile()">
            <div ngbDropdown class="btn-group pull-left user-profile dropdown"
                [ngClass]="{'withBorder': isFullScreenEnabled}">
                <button ngbDropdownToggle id="user-profile-open" type="button" class="icon-container profile-photo dropdown-toggle"
                    [ngClass]="{'withBorder': isFullScreenEnabled}"
                    (click)="$event.stopPropagation(); renderHeaderForClick($event); logMiniProfileTelemetryInfo('ProfileIconClick', isMiniProfileOpen);"
                    [title]="userProfile.displayName ? userProfile.displayName : 'user profile'"
                    aria-label="profile button">
                    <img class="icon iconDisplayImage" id="imgPro" [alt]="userProfile.displayName"
                        [src]="userThumbnailPhoto"
                        [title]="userProfile.displayName ? userProfile.displayName : 'user profile'" />
                </button>
                <div class="arrow-up"></div>
                <ul ngbDropdownMenu aria-labelledby="user-profile-open" (click)="$event.stopPropagation()"
                    class="fxp-profile-dropdown-menu">
                    <li class="user-apps-app photo-holder" (keydown)="renderHeaderMenuForKeydown($event)">
                        <div class="user-profile-info">
                            <div class="photo">
                                <img class="photo-pic" id="miniImgPro" [alt]="userProfile.displayName"
                                    [src]="userThumbnailPhoto" />
                            </div>
                            <div class="user-info">
                                <label class="name">{{ userProfile.displayName }}</label>
                                <label class="desg">{{ userProfile.addressBookTitleDesc ?
                                    userProfile.addressBookTitleDesc : userProfile.businessRole }}</label>
                                <label class="domain" *ngIf="!manageProfileFlight">{{ userProfile.businessDomain ?
                                    userProfile.businessDomain : '' }}</label>
                            </div>
                        </div>
                        <div class="manage-profile-box">
                            <ng-container *ngFor="let config of profileFlyoutConfig">
                                <ng-container *ngIf="config.isEnabled">
                                    <a class="manage-profile" [id]="config.id" [href]="config.link"
                                        [title]="config.tooltip" [target]="config.target"
                                        (click)="logMiniProfileTelemetryInfo('ProfileLinkClick', isMiniProfileOpen, {link: config.link, displayName: config.displayName, featureFlag: config.featureFlag})">
                                        {{ config.displayName }}
                                    </a>
                                    <i class="icon-popout" *ngIf="config.popoutIcon">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="currentColor"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M2.49999 1C1.67157 1 0.999995 1.67157 0.999995 2.5V9.5C0.999995 10.3284 1.67157 11 2.49999 11H9.49996C10.3284 11 10.9999 10.3284 10.9999 9.5V7.26923C10.9999 6.99309 11.2238 6.76923 11.4999 6.76923C11.7761 6.76923 11.9999 6.99309 11.9999 7.26923V9.5C11.9999 10.8807 10.8807 12 9.49996 12H2.49999C1.11928 12 0 10.8807 0 9.5V2.5C0 1.11929 1.11928 0 2.49999 0H4.73075C5.00689 0 5.23074 0.223858 5.23074 0.5C5.23074 0.776142 5.00689 1 4.73075 1H2.49999ZM6.76926 0.5C6.76926 0.223858 6.99311 0 7.26925 0H11.5C11.7761 0 12 0.223858 12 0.5V4.73077C12 5.00691 11.7761 5.23077 11.5 5.23077C11.2239 5.23077 11 5.00691 11 4.73077V1.70711L7.6228 5.08433C7.42754 5.27959 7.11096 5.27959 6.9157 5.08433C6.72044 4.88906 6.72044 4.57248 6.9157 4.37722L10.2929 1H7.26925C6.99311 1 6.76926 0.776142 6.76926 0.5Z"
                                                fill="#323130" />
                                        </svg>
                                    </i>
                                </ng-container>
                            </ng-container>
                            <button id="copyicon" title="Copy profile URL" class="copy-icon"
                                (click)="copyToClipboard(); $event.stopPropagation();">
                                <i class="icon icon-copy"></i>
                            </button>
                            <div class="copy-tooltip" id="copytooltip" aria-live="assertive">Copy Profile URL</div>
                        </div>

                        <div class="white-box"></div>
                        <label class="report-label">Reports to</label>
                        <div class="user-report-info">
                            <div class="report-photo">
                                <img class="report-img" [alt]="userProfile.reportsToDisplayName"
                                    [src]="reportsToThumbnailPhoto" />
                            </div>
                            <div class="report-info">
                                <label class="report-name">{{ userProfile.reportsToDisplayName }}</label>
                                <div class="report-contact">
                                    <a [href]="'sip:' + userProfile.reportsTo + '@microsoft.com'"
                                        [title]="'Chat with ' + userProfile.reportsToDisplayName"
                                        [attr.aria-label]="'Chat with ' + userProfile.reportsToDisplayName"
                                        (click)="logMiniProfileTelemetryInfo('ChatIconClick', isMiniProfileOpen)">
                                        <i class="icon icon-teamsChat"></i>
                                    </a>
                                    <a [href]="'mailto:' + userProfile.reportsTo + '@microsoft.com'"
                                        [title]="'Send a mail to ' + userProfile.reportsToDisplayName"
                                        [attr.aria-label]="'Send a mail to ' + userProfile.reportsToDisplayName"
                                        (click)="logMiniProfileTelemetryInfo('MailIconClick', isMiniProfileOpen)">
                                        <i class="icon icon-mail"></i>
                                    </a>
                                </div>
                                <div *ngIf="userProfile.additionalInfo">
                                    <div class="info-item" *ngFor="let info of userProfile.additionalInfo | keyvalue">
                                        <label class="title space">{{ info.key }}</label>
                                        <label class="title data">{{ info.value }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <button type="button" class="icon-container" title="Maximize" (click)="maximize()" *ngIf="isFullScreenEnabled">
            <i class="icon icon-maximize"></i>
        </button>
    </div>
    <div class="clearfix"></div>
</section>
<!-- <act-obo-header *ngIf="actOnBehalfOfUserActive"></act-obo-header> -->
<div *ngIf="actOnBehalfOfUserActive">
<div class="popnew" id="newpop">
    <ul role="presentation">
      <li id="OBOHeader_ActOnBehalfOfTxt" role="presentation" class="actobo" role="text">
        {{ oboUIStrings.ActingOnBehalfOf }}
      </li>
      <li role="presentation" id="OBOHeader_UserName" class="obousername">
        <a
          *ngIf="showViewFullProfileLink"
          [href]="OBOUser.href"
          class="obousername_text"
          role="link"
          [attr.aria-label]="oboUIStrings.ActingOnBehalfOf + ' ' + OBOUser.name"
          tabindex="1"
        >
          {{ OBOUser.name }}
        </a>
        <span
          *ngIf="!showViewFullProfileLink"
          class="obousername_text"
          [attr.aria-label]="oboUIStrings.ActingOnBehalfOf + ' ' + OBOUser.name"
        >
          {{ OBOUser.name }}
        </span>
      </li>
    </ul>
    <button
      id="OBOHeader_CloseBtn"
      class="icon-container pull-right"
      (click)="closeActOnBehalfOfUserClick($event)"
      tabindex="1"
      title="act on behalf of close"
    >
      <i class="icon icon-close"></i>
    </button>
  </div>
</div>
  