import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { TelemetryConstants } from '../telemetry/TelemetryConst';
import { FxpConstants } from '../common/ApplicationConstants';
import { FxpRootScopeService, IRootSubjects } from '../services/FxpRootScopeService';
import { FxpStateService } from '../../app/services/FxpStateRoutingHelperService';
import { FxpLoggerService } from '../telemetry/fxpLogger';
import { UserInfoService } from '../services/UserInfoService';
import { TelemetryContext } from '../telemetry/telemetrycontext';
import { OBOUserService } from '../services/OBOUserService';
import { FxpGlobalStoreService } from '../services/fxp.global.store.service';
import { FxpBreadcrumbService } from '../services/FxpBreadcrumbService';
import { FxpMessageService } from '../../app/banner/FxpMessageService';
import { Subscription, fromEvent } from 'rxjs';
import { HideLoader, ShowLoader } from '../../app/loader/loader.actions';
import { ErrorCodes } from '../constants/errorCodes';
import { ErrorSeverityLevel } from '../telemetry/ErrorSeverityLevel';


@Directive({
    selector: '[fxpLeftNavLink]'
})
export class FxpLeftNavLinkDirective implements OnInit, OnDestroy {
    @Input('fxpLeftNavLink') fxpLeftNavLink: any;
    @Input('isLeftNavItem') isLeftNavItem: boolean;
    private fxpRootScopeService: FxpRootScopeService;
    private fxpRootScope: IRootSubjects;

    private businessProcessNameL0: string;
    private displayNameL0: string;

    private fxpTelemetryContext: TelemetryContext;

    private subscriptions: Subscription[] = [];

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private viewContainerRef: ViewContainerRef,
        private state: FxpStateService,
        private fxplogger: FxpLoggerService,
        private userInfoService: UserInfoService,
        private fxpMessageService: FxpMessageService,
        private fxpBreadcrumbService: FxpBreadcrumbService,
        private fxpGlobalStoreService: FxpGlobalStoreService,
    ) {

    }

    ngOnInit() {
        if (!this.fxpLeftNavLink) return;
        this.fxpRootScopeService = FxpRootScopeService.getInstance();
        this.fxpRootScopeService.rootScopeSubject.subscribe((data) => {
            this.fxpRootScope = data;
        });
        this.fxpTelemetryContext = TelemetryContext.getInstance();
        this.setupLinkAttributes();
        const clickSubscription = fromEvent(this.el.nativeElement, 'click').subscribe(event => this.onElementClick(event));
        this.subscriptions.push(clickSubscription);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    private setupLinkAttributes() {
        const item = this.fxpLeftNavLink;
        const target = item.openInline ? '_self' : '_blank';
        this.renderer.setAttribute(this.el.nativeElement, 'target', target);

        let url: string;
        if (!item.targetEventName) {
            url = item.targetUIStateName ? this.state.href(item.targetUIStateName) : item.targetURL;

            if (item.businessProcessName === "Dashboard") {
                this.renderer.setAttribute(this.el.nativeElement, 'href', "#/Dashboard");
            } else if (url) {
                this.renderer.setAttribute(this.el.nativeElement, 'href', url);
            }
        }
    }

    private onElementClick(event: any) {

        var user_TenantRoutes = window["loggedInUserConfig"].Routes || [];
        var lazyLoadconfig = window["tenantConfiguration"].AdditionalConfigurationContainer || {};
        var enableLazyLoading = lazyLoadconfig.EnableLazyLoading || false;

        const item = this.fxpLeftNavLink;
        let captureChildLinkName: string;

        const initiateClickStartTime = (displayName: string) => {
            this.fxpRootScopeService.setRootScopeField('startTime', performance.now());
            this.fxpRootScopeService.setRootScopeField('displayName', displayName);
        };

        if (item.dependenciesMissing && item.dependenciesMissing === true) {
            this.fxpMessageService.addMessage(this.fxpRootScope.fxpUIConstants.UIMessages.StateChangeErrorDueToMissingModules.ErrorMessageTitle, FxpConstants.messageType.error);
            this.logLeftNavLoadFailEvent(item.displayName);
            event.preventDefault();
        } else {
            if (this.state.getCurrentStateName() == item.targetUIStateName) {
                this.state.reload();
            }

            if (item.businessProcessName && item.parentId == null) {
                //capturing businessProcessName for L0 when we click on L0 Item
                this.businessProcessNameL0 = item.businessProcessName;
                //capturing displayName for L0  when we click on L0 Item
                this.displayNameL0 = item.displayName;
            }
            else {
                this.businessProcessNameL0 = "";
                this.displayNameL0 = "";
            }

            if (this.el.nativeElement.getAttribute('href') != "" && this.el.nativeElement.getAttribute('href') != "#") {
                if (this.el.nativeElement.getAttribute('target') === '_self' && !event.ctrlKey && !event.shiftKey) {
                    initiateClickStartTime(item.displayName);
                }
                captureChildLinkName = item.parentId != null ? item.displayName : "";
                this.logLeftNavActivity(captureChildLinkName);
                if (this.isLeftNavItem) {
                    this.fxpBreadcrumbService.isLeftNavItemClicked = true;
                }
            }

            if (item.targetEventName && item.targetUIStateName) {
                const targetUIState_config = user_TenantRoutes.find(x => x.StateName == item.targetUIStateName);
                if (enableLazyLoading && targetUIState_config && targetUIState_config.LazyLoad) {
                    this.fxpGlobalStoreService.DispatchGlobalAction("Platform", ShowLoader({ loadingText: "Loading..." }));
                    console.log("LazyLoad Start Timer for TargetState Bundle:" + targetUIState_config.LazyLoad.toString() + new Date());
                    if (enableLazyLoading && targetUIState_config && targetUIState_config.LazyLoad) {
                        console.log("LazyLoad Start Timer for TargetState Bundle:" + targetUIState_config.LazyLoad.toString() + new Date());

                        // TODO: Uncomment this once Lazy Loading is done. 
                        this.fxpGlobalStoreService.DispatchGlobalAction("Platform", ShowLoader({ loadingText: "Loading..." }));
                        import(targetUIState_config.LazyLoad).then((module) => {
                            const componentFactory = module.default;
                            const componentRef = this.viewContainerRef.createComponent(componentFactory);
                            this.fxpGlobalStoreService.DispatchGlobalAction("Platform", HideLoader({}));

                            if (!event.ctrlKey && !event.shiftKey) {
                                initiateClickStartTime(item.displayName);
                                this.fxpRootScopeService.broadcast(item.targetEventName);
                                if (this.isLeftNavItem) {
                                    this.fxpBreadcrumbService.isLeftNavItemClicked = true;
                                }
                            }
                        }).catch((err: any) => {
                            this.fxpGlobalStoreService.DispatchGlobalAction("Platform", HideLoader({}));
                            console.error("error occurred while doing lazyloading " + err);
                            this.fxplogger.logError(`${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.LeftnavLink`, err.message, ErrorCodes.ErrorOnLeftNavClick, err.stack, null, null, null, ErrorSeverityLevel.Medium);
                        });
                    } else {
                        this.fxpRootScopeService.broadcast(item.targetEventName);
                        if (this.isLeftNavItem) {
                            this.fxpBreadcrumbService.isLeftNavItemClicked = true;
                        }
                    }
                } else {
                    this.fxpRootScopeService.broadcast(item.targetEventName);
                    if (this.isLeftNavItem) {
                        this.fxpBreadcrumbService.isLeftNavItemClicked = true;
                    }
                }
            }
        }
    }

    private logLeftNavActivity(captureChildLinkName: string) {
        const leftNavClickCount = 1;
        var userInfo = this.userInfoService.getCurrentUserData();
        const classNameFxpLeftnavLink = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.LeftnavLink`;
        var fxpConstants = FxpConstants;

        // Creating PropertyBag
        var propbag = this.fxplogger.createPropertyBag();

        // Adding businessProcessNameL0 to propbag
        propbag.addToBag(fxpConstants.metricConstants.L0BusinessProcessName, this.businessProcessNameL0);

        // Adding L0Name to propbag
        propbag.addToBag(fxpConstants.metricConstants.L0Name, this.displayNameL0);

        // Adding L0Name_L1Name to propbag
        propbag.addToBag(fxpConstants.metricConstants.L0Name_L1Name, captureChildLinkName);

        // Adding UserRoleGroup name to propbag
        propbag.addToBag(fxpConstants.metricConstants.UserRoleGroup, userInfo.roleGroupName);

        // logging LeftNavigationClickCountbyRoleGroup metric data
        this.fxplogger.logMetric(classNameFxpLeftnavLink, fxpConstants.metricConstants.LeftNavigationClickCountbyRoleGroup, leftNavClickCount, propbag);

        // Adding UserBusinessRole to propbag
        propbag.addToBag(fxpConstants.metricConstants.UserBusinessRole, userInfo.businessRole);

        // logging LeftNavigationClickCountbyRole metric data
        this.fxplogger.logMetric(classNameFxpLeftnavLink, fxpConstants.metricConstants.LeftNavigationClickCountbyRole, leftNavClickCount, propbag);

        // Adding UserAgent to propbag
        propbag.addToBag(fxpConstants.metricConstants.UserAgent, navigator.userAgent);

        // Adding Geography to propbag
        propbag.addToBag(fxpConstants.metricConstants.Geography, this.fxpTelemetryContext.getGeography());

        // logging LeftNavigationClick event
        this.fxplogger.logEvent(classNameFxpLeftnavLink, `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.LeftNavigationClick`, propbag);
    }

    private logLeftNavLoadFailEvent(linkName: string) {
        var userInfo = this.userInfoService.getCurrentUserData();
        const classNameFxpLeftnavLink = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.LeftnavLink`;
        var fxpConstants = FxpConstants;

        // Creating PropertyBag
        var propbag = this.fxplogger.createPropertyBag();

        // Adding businessProcessNameL0 to propbag
        propbag.addToBag(fxpConstants.metricConstants.L0BusinessProcessName, this.businessProcessNameL0);

        // Adding L0Name to propbag
        propbag.addToBag(fxpConstants.metricConstants.L0Name, this.displayNameL0);

        // Adding L0Name_L1Name to propbag
        propbag.addToBag(fxpConstants.metricConstants.L0Name_L1Name, linkName);

        // Adding UserRoleGroup name to propbag
        propbag.addToBag(fxpConstants.metricConstants.UserRoleGroup, userInfo.roleGroupName);

        // Adding UserBusinessRole to propbag
        propbag.addToBag(fxpConstants.metricConstants.UserBusinessRole, userInfo.businessRole);

        // Adding UserAgent to propbag
        propbag.addToBag(fxpConstants.metricConstants.UserAgent, navigator.userAgent);

        // Adding Geography to propbag
        propbag.addToBag(fxpConstants.metricConstants.Geography, this.fxpTelemetryContext.getGeography());

        // logging LeftNavigationClick event
        this.fxplogger.logEvent(classNameFxpLeftnavLink, `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.LeftNavClickFailDueToMissingDependencies`, propbag);
    }
}
