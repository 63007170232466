import {
    Component,
    Injector
} from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FxpSharedComponentLoader } from '@fxp/fxpsharedcomponentloader';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})

export class AppComponent {

    constructor(injector: Injector) {
        //define customElements
        const sharedComponentLoaderControl = createCustomElement(FxpSharedComponentLoader, { injector });
        customElements.define('fxp-shared-component-control', sharedComponentLoaderControl);
    }

}

