import { NgModule } from "@angular/core";
import { FxpFooterComponent } from "./fxpfooter.component";
import { FxpConfigurationService } from "../../../js/services/FxpConfiguration";
import { CommonModule } from "@angular/common";


@NgModule({
    declarations: [FxpFooterComponent],
    entryComponents: [FxpFooterComponent],
    providers: [
        FxpConfigurationService,
    ],
    imports: [CommonModule]
})
export class FxpFooterModule { }
