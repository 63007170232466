import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[fxpHelpOutsideClick]'
})
export class FxpHelpOutsideClickDirective {
  @Input('fxpHelpOutsideClick') closeHelpMethod: () => void;

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    const clickedInside = this.elementRef.nativeElement.contains(event.target as Node);
    const isContextualHelp = (event.target as Element).closest('.contextual-help');
    if (!clickedInside && !isContextualHelp) {
      if (this.closeHelpMethod) {
        this.closeHelpMethod();
      }
    }
  }
}
