import { NgModule } from "@angular/core";
import { SharedModule } from "../../modules/fxpheader.shared.module";
import { FxpGlobalStoreService } from "../../../js/services/fxp.global.store.service";
import { FxpConfigurationService } from "../../../js/services/FxpConfiguration";
import { UserInfoService } from "../../../js/services/UserInfoService";
import { DeviceFactoryProvider, FxpContextServiceProvider, FxpLoggerServiceProvider, FxpMessageServiceProvider, FxpSignalRServiceProvider, NotificationActionCenterProvider, NotificationStoreProvider, SettingsServiceProvider } from "../../services/FxpServices";

@NgModule({
    declarations: [],
    entryComponents: [],
    providers: [
        FxpConfigurationService,
        NotificationStoreProvider,
        NotificationActionCenterProvider,
        UserInfoService,
        FxpMessageServiceProvider,
        FxpGlobalStoreService,
        FxpLoggerServiceProvider,
        FxpSignalRServiceProvider,
        SettingsServiceProvider,
        FxpContextServiceProvider,
        DeviceFactoryProvider,
    ],
    imports: [SharedModule]
})
export class FxpNotificationModule { }