import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FxpSystemUpdateMsgComponent } from './fxpsystemupdatemsg.component';
import { PlannedDownTimeServiceProvider } from '../../services/FxpServices';


@NgModule({
    declarations: [FxpSystemUpdateMsgComponent],
    entryComponents: [FxpSystemUpdateMsgComponent],
    providers: [
        PlannedDownTimeServiceProvider
    ],
    imports: [CommonModule]
})
export class FxpSystemUpdateMsgModule { }
