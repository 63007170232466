import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FxpBotLauncherComponent } from "../components/fxpbot/fxpbot.component";
import { FxpNotificationComponent } from "../components/fxpnotification/fxpnotification.component";
import { PageTourLauncherComponent } from "../components/pageTour-Component/pageTour.component";
import { FormsModule } from "@angular/forms";
import { FxpHelpMenuComponent } from "../components/fxphelp/fxphelp.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FxpHelpArticleDirective } from "../components/fxphelp/fxp-help-article.directive";
import { FxpHelpOutsideClickDirective } from "../components/fxphelp/fxp-Help-OutsideClick.directive";
import { AriaExpandedDirective } from "../components/fxphelp/fxp-aria-expand.directive";
import { FxpBreadcrumbComponent } from "../components/fxpbreadcrumb/fxpbreadcrumb.component";
import { RouterModule } from "@angular/router";
import { FxpLoaderComponent } from "../components/fxploader/fxploader.component";
@NgModule({
  declarations: [
    FxpBotLauncherComponent,
    FxpNotificationComponent,
    PageTourLauncherComponent,
    FxpHelpMenuComponent,
    FxpHelpArticleDirective,
    FxpHelpOutsideClickDirective,
    AriaExpandedDirective,
    FxpBreadcrumbComponent,
    FxpLoaderComponent,
  ],
  imports: [CommonModule, FormsModule, NgbModule, RouterModule],
  exports: [
    FxpBotLauncherComponent,
    FxpNotificationComponent,
    PageTourLauncherComponent,
    FxpHelpMenuComponent,
    FxpHelpArticleDirective,
    FxpBreadcrumbComponent,
    FxpHelpOutsideClickDirective,
    AriaExpandedDirective,
    FxpLoaderComponent,
  ],
  entryComponents: [
    FxpBotLauncherComponent,
    FxpNotificationComponent,
    FxpBreadcrumbComponent,
    PageTourLauncherComponent,
    FxpHelpMenuComponent,
    FxpLoaderComponent,
  ],
  bootstrap: [FxpHelpMenuComponent],
})
export class SharedModule {}
